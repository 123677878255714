<template>
  <div class="article">
    <!-- 点赞，评论等 -->
    <div class="like">
      <approve :username="username" :articleId="''+articleId"></approve>
      <div>
        <span style="display:block;text-align:center">{{this.remarkCount}}</span>
        <el-tooltip class="item" effect="dark" content="评论" placement="right">
          <a href="#comment">
            <el-avatar ref="remark" icon="el-icon-chat-dot-round" alt="评论"></el-avatar>
          </a>
        </el-tooltip>
      </div>
      <!-- 举报 -->
      <accuse :username="username" :article="article"></accuse>
    </div>
    <!-- 预览图片 -->
    <div @click="preview_imgsrc=null" v-if="preview_imgsrc" class="preview">
      <img :src="preview_imgsrc" alt="none" />
    </div>
    <!-- 文章复制限制 -->
    <copyLimit :article="article"></copyLimit>
    <div class="article-main">
      <!-- 文章主体 -->
      <el-card>
        <span style="font-size: 22px; color: #bf360c;">
          <strong>{{article.title}}</strong>
        </span>
        <el-divider content-position="left">
          作者:
          <a href="#" style=" text-decoration: none;" @click="toHomePage()">{{article.name}}</a>
          <el-divider direction="vertical"></el-divider>
          发布时间:{{article.createTime}}
          <el-divider direction="vertical"></el-divider>
          阅读:{{article.viewCount}}
          <router-link
            style="text-decoration:none;"
            v-if="isOwner()"
            :to="{name:'articleEdit',params: { articleId: article.id }}"
          >
            <el-divider direction="vertical"></el-divider>
            <span>修改</span>
          </router-link>
        </el-divider>
        <div class="content" id="content" ref="content" v-html="compiledMarkdown"></div>
      </el-card>
      <!-- 评论框 -->
      <el-card>
        <commentBox id="comment" :clearCach="clearCach" :parent="parent" @submit-box="submitBox"></commentBox>
        <commentList :comments="remark" :owner="isOwner()" @update-remark="getArticleRemarkById"></commentList>
      </el-card>
      <recommend v-if="article.title" :title="article.title" ></recommend>
    </div>
    <div class="article-aside">
      <div style="margin-top:10x">
        目录
        <ul class="catalog">
          <template v-for="(item, index) in catalog">
            <li :key="index" :style="'margin-left:'+item.level * 15 + 'px;margin-top:10px;'">
              <a
                style="text-decoration: none;color: inherit;font-weight:bold;"
                :href="`#${item.id}`"
                :title="item.title"
              >{{item.title}}</a>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import commentList from "@/components/comment/commentList";
import commentBox from "@/components/comment/commentBox";
import { encrypt } from "@/utils/encoder.js";
import { catalog } from "@/utils/catalog.js";
import approve from "@/components/sideBar/approve";
import accuse from "@/components/sideBar/accuse";
import copyLimit from "@/components/copyLimit/copyLimit";
import recommend from "@/components/recommend/recommend";
import { markdown } from "@/utils/simple-markdown.js";
import Clipboard from "clipboard";
export default {
  name: "articleInfo",
  data() {
    return {
      article: {},
      articleList: {},
      catalog: [],
      remark: [],
      parent: {
        id: -1,
        articleId: this.$route.params.articleId
      },
      articleId: 0,
      clearCach: 0,
      remarkCount: 0,
      preview_imgsrc: null,
    };
  },
  components: {
    commentBox,
    commentList,
    approve,
    accuse,
    copyLimit,
    recommend
  },
  computed: {
    // 文章加载
    compiledMarkdown() {
      let result = markdown.render(this.article.content || "");
      return result;
    },
    username() {
      return this.$store.state.permission.user.username;
    },
  },
  watch: {
    // $route(to, from) {
    //   // 对路由变化作出响应...
    //   this.article = {};
    //    this.getArticleInfoById();
    // }
  },
  async beforeRouteUpdate(to, from, next) {
    // react to route changes...
    // don't forget to call next()
    // hash 值存在就是 目录锚点
    if (!to.hash) {
      this.article = {};
      await this.getArticleInfoById(to.params.articleId);
      await this.getArticleRemarkById(to.params.articleId);
      this.parent.articleId = to.params.articleId;
      this.articleId = to.params.articleId;
      this.initCatalog();
    }
    next();
  },
  async created() {
    await this.getArticleInfoById();
    await this.getArticleRemarkById();
    this.initCatalog();
    this.imgPriview();
  },
  methods: {
    initCatalog() {
      // 页面渲染完毕生成目录
      this.$nextTick(() => {
        this.catalog = catalog(this.$refs["content"]);
      });
    },
    async getArticleInfoById(value) {
      if (!value) {
        value = this.$route.params.articleId;
      }
      // 加载文章信息
      const { data: res } = await this.$api.getArticleInfoById(value);
      this.article = res.data;
      this.setMate(this.article);
    },
    // 获取文章评论
    async getArticleRemarkById(value) {
      if (!value) {
        value = this.$route.params.articleId;
      }
      const { data: res } = await this.$api.getArticleRemarkById(value);
      // this.$store.dispatch('',value)
      this.remark = res.data;
      const { data: resCount } = await this.$api.getArticleRemarkCount(value);
      this.remarkCount = resCount.data;
    },
    // 提交评论
    async submitBox(msg) {
      const { data: res } = await this.$api.addArticleRemark(msg);
      this.remark.push(res.data);
      // 清除缓存
      if (this.clearCach == 0) {
        this.clearCach = 1;
      } else {
        this.clearCach = 0;
      }
    },
    // 跳转个人首页
    toHomePage() {
      this.$router.push({
        name: "homepage",
        params: { username: encrypt(this.article.author) }
      });
    },
    isOwner() {
      if (this.article.author == this.username) {
        return true;
      } else {
        return false;
      }
    },
    setMate(value) {
      document.title = this.article.title;
      document
        .querySelector('meta[name="keywords"]')
        .setAttribute("content", value.title);
      if (this.article.digest) {
        document
          .querySelector('meta[name="description"]')
          .setAttribute("content", value.digest);
      }
      let head = document.getElementsByTagName("head")[0];
      let oldLink = document.querySelector('link[ref="canonical"]')
      if(oldLink){
        oldLink.href = `https://zszxz.com/article/${value.id}`
      }else{
        let link = document.createElement("link");
        link.setAttribute('ref',"canonical")
        link.href = `https://zszxz.com/article/${value.id}`
        head.appendChild(link);
      }
    },
    imgPriview() {
      this.$el.addEventListener("click", event => {
        event = event ? event : window.event;
        let ele = event.srcElement ? event.srcElement : event.target;
        if (ele.tagName === "IMG") {
          if (!this.preview_imgsrc) {
            this.preview_imgsrc = ele.src;
          } else {
            this.preview_imgsrc = null;
          }
        }
      });
    }
  }
};
</script>
 
<style scoped>
@import "../../assets/css/easy-markdown.css";
.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 30;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);
  transition: opacity ease 0.3s;
  /* pointer-events: none; */
}
.article {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  position: relative;
}

.article-main {
  width: 900px;
  margin-right: 260px;
}
.article-aside {
  width: 250px;
  position: fixed;
  top: 80px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 50%;
}
.el-card {
  flex: 1;
}
.signature {
  padding: 12px 24px 12px 20px;
  margin-top: 15px;
  font-size: 15px;
  background-color: #f8f8f8;
  position: relative;
  border-radius: 4px;
  border-left: 4px solid #f8f8f8;
}
.like {
  width: 50px;
  height: 185px;
  /* background-color: blue; */
  top: 250px;
  position: fixed;
  z-index: 2000;
  margin-right: 1200px;
  text-align: center;
}
</style>

